<template>
  <div class="absolute top-0 w-screen h-screen flex flex-col items-center justify-center z-10">
    <div class="relative w-full gap-4">
      <div class="sign w-full md:w-1/2 text-5xl md:text-6xl uppercase tracking-wide -mt-8 lg:-mt-20">
        <span class="fast-flicker">-</span>late<span class="flicker">s</span>t -
      </div>
    </div>

    <div class="tunes-container inline-block flex items-center mx-4 py-4 lg:py-10 gap-5 lg:gap-10 2xl:gap-24 w-full lg:w-11/12 2xl:w-5/6 overflow-x-auto overflow-y-hidden">
      <div :class="{'ml-auto': i === 0, 'mr-auto': i === tunes.length-1}" v-for="(tune, i) in tunes" :key="i">
        <div class="relative h-56 lg:h-72" :class="[tune.url.indexOf('youtube') === -1 ? 'w-64 lg:w-72' : 'w-72 lg:w-128']"
             v-if="tune.image === ''">
          <iframe :src="tune.url" class="w-full h-full" allowtransparency="true" allow="encrypted-media"
                  allowfullscreen></iframe>
        </div>

        <div class="flip-card relative w-64 lg:w-72 h-56 lg:h-72" :class="{'is-flipped': tune.isFlipped }" v-else>
          <div class="card-front absolute top-0 left-0 w-full h-full" @click="tune.isFlipped = true">
            <div class="relative cursor-pointer w-full h-full">
              <h2 class="absolute bottom-0 w-full uppercase text-xs lg:text-base tracking-wide text-white bg-black bg-opacity-60 p-2">
                {{ tune.title }}</h2>
              <div class="w-full h-full bg-cover bg-center" :style="{backgroundImage: 'url(' + tune.image + ')'}"/>
            </div>
          </div>
          <div class="card-back absolute top-0 left-0 w-full h-full" v-if="tune.url !== ''">
            <iframe :src="tune.url" class="w-full h-full" allowtransparency="true" allow="encrypted-media"
                    allowfullscreen></iframe>
            <button @click="tune.isFlipped = false" class="close-tune">
              <svg class="w-5 h-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {tunes} from '@/tunes'

export default {
  name: 'Home',

  data() {
    return {
      tunes: []
    }
  },

  mounted() {
    this.tunes = tunes.filter(tune => tune.isHighlighted)
    if (window.addEventListener) {
      // IE9, Chrome, Safari, Opera
      window.addEventListener('mousewheel', this.scrollHorizontally, false);
      // Firefox
      window.addEventListener('DOMMouseScroll', this.scrollHorizontally, false);
    } else {
      // IE 6/7/8
      window.attachEvent('onmousewheel', this.scrollHorizontally);
    }
  },
}
</script>

<style scoped>
@font-face {
  font-family: Clip;
  src: url("/Clip.ttf");
}

.sign {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
      ellipse 50% 35% at 50% 50%,
      #6b1839,
      transparent
  );
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-family: "Clip";
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
  -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
  0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  animation: shine 2s forwards, flicker 3s infinite;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

.tunes-container {
  perspective: 1000px;

  .flip-card {
    transition: transform 800ms ease-out;
    transform-style: preserve-3d;

    &:not(.is-flipped):hover {
      -webkit-animation: wiggle 500ms ease-in-out; /* Safari 4+ */
      -moz-animation:    wiggle 500ms ease-in-out; /* Fx 5+ */
      -o-animation:      wiggle 500ms ease-in-out; /* Opera 12+ */
      animation:         wiggle 500ms ease-in-out;
      animation-delay: 100ms;
    }

    > div {
      backface-visibility: hidden;
    }

    .card-back {
      transform: rotateY(180deg);

      .close-tune {
        @apply absolute z-40 p-2 bg-black bg-opacity-100 text-white cursor-pointer leading-none;
        top: 0;
        right: 0;

        &:focus {
          @apply outline-none;
        }
      }
    }

    &.is-flipped {
      transform: rotateY(180deg);

      .card-back {
        z-index: 40;
      }
      .card-front {
        z-index: 10;
      }
    }

    &:not(.is-flipped) {
      .card-back {
        z-index: 10;
      }
      .card-front {
        z-index: 40;
      }
    }
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .h-56 {
    @apply h-40;
  }

  .sign {
    @apply text-4xl;
  }
}

@keyframes wiggle {
  0% {
    transform: rotateY(0);
  }
  30% {
    transform: rotateY(-5deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  70% {
    transform: rotateY(3deg);
  }
  100% {
    transform: rotateY(0);
  }
}
</style>
